import React, { useState, useCallback } from 'react'
import { Button, Container, Section, Columns, Progress } from 'react-bulma-components';
import { useNavigate } from "react-router-dom";
import { generateMathQuestion, speakOutLoud, positiveNotificationMsg, ARITHMETIC_MODES_CONFIG, NOTIFICATION_DURATION_IN_SECONDS } from './utils.js'
import InGameNotification from './InGameNotification.js';
import NavbarActions from './NavbarActions.js';
import Confetti from 'react-confetti'
import useWindowSize from './hooks/useWindowSize.js'
import './SimpleArithmeticApp.css';

const SimpleArithmeticApp = ({mode}) => {
  const [isGameStarted, setIsGameStarted] = useState(false)
  const [gameQuestions, setGameQuestions] = useState(null)
  const [gameScore, setGameScore] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [notificationMsg, setNotificationMsg] = useState(null)
  const [answerText, setAnswerText] = useState('?')
  const [showConfetti, setShowConfetti] = useState(false)
  const navigate = useNavigate()
  const {width, height} = useWindowSize()

  const NUM_QUESTIONS = 10
  let notificationDelayTimeout = null

  const handleStartGame = useCallback((level) => {
    setIsGameStarted(true)
    let thisGameQuestions = []
    for (let i = 0; i < NUM_QUESTIONS; ++i) {
      thisGameQuestions.push(generateMathQuestion(level))
    } 
    setCurrentIndex(0)
    setGameScore(0)
    setGameQuestions(thisGameQuestions)
    speakOutLoud(thisGameQuestions[0][0] + ' ' + ARITHMETIC_MODES_CONFIG[mode]['speakOutSign'] + ' ' + thisGameQuestions[0][1])
  }, [])

  const handleEndGame = useCallback((gameScore) => {
    setAnswerText('?')
    if (gameScore) {
      setNotificationMsg(`Score = ${gameScore} / ${NUM_QUESTIONS}`)
      speakOutLoud(`Your score is ${gameScore} out of ${NUM_QUESTIONS}`)
      if (gameScore === NUM_QUESTIONS) {
        setShowConfetti(true)
      }
      notificationDelayTimeout = setTimeout(() => {
        setShowConfetti(false)
        setIsGameStarted(false)
      }, (NOTIFICATION_DURATION_IN_SECONDS+3) * 1000)
    } else {
      setIsGameStarted(false)
    }
  }, [gameScore])

  const goNext = useCallback((gameScore) => {
    if (currentIndex === (gameQuestions.length-1)) {
      speakOutLoud("Great job!")
      handleEndGame(gameScore)
      return
    } else {
      setCurrentIndex(currentIndex+1)
    }
    speakOutLoud(gameQuestions[currentIndex+1][0] + ' ' + ARITHMETIC_MODES_CONFIG[mode]['speakOutSign'] + ' ' + gameQuestions[currentIndex+1][1])
  }, [currentIndex, gameQuestions])

  const checkAnswer = useCallback((updatedAnswerText) => {
    clearInterval(notificationDelayTimeout)
    setNotificationMsg(null)

    const answer = ARITHMETIC_MODES_CONFIG[mode].computeAnswer(gameQuestions[currentIndex][0], gameQuestions[currentIndex][1])
    if ((updatedAnswerText + '').length !== (answer + '').length) {
      return
    }

    let thisGameScore = gameScore.valueOf()
    // console.log(gameQuestions[currentIndex][0], gameQuestions[currentIndex][1], {answer}, {answerText})
    if (parseInt(updatedAnswerText) === answer) {
      thisGameScore += 1
      setGameScore(thisGameScore)
      setNotificationMsg(positiveNotificationMsg('Correct!'))
      speakOutLoud("That's correct!")
    } else {
      setNotificationMsg('Incorrect')
      speakOutLoud("That's incorrect")
    }
    notificationDelayTimeout = setTimeout(() => {
      setAnswerText('?')
      goNext(thisGameScore)
    }, NOTIFICATION_DURATION_IN_SECONDS * 1000)
  }, [gameQuestions, currentIndex, answerText, gameScore])

  const onInput = useCallback((val) => {
    let updatedAnswerText = answerText + ''
    if (val >= 0) {
      if (updatedAnswerText.length > 2) {
        return
      }
      if (updatedAnswerText === '?') {
        updatedAnswerText = val
      } else {
        updatedAnswerText = updatedAnswerText + val
      }
    } else { // Delete input
      updatedAnswerText = updatedAnswerText.slice(0, -1)
      if (updatedAnswerText.length === 0) {
        updatedAnswerText = '?'
      }
    }

    setAnswerText(updatedAnswerText)
    checkAnswer(updatedAnswerText)
  }, [gameQuestions, answerText])

  return (
    <div className="math-app" style={{ backgroundImage: "url(/bg.jpeg)" }}>
      {showConfetti === true ? <Confetti width={width} height={height} run={showConfetti} gravity={0.5} /> : ''}
      <NavbarActions isGameStarted={isGameStarted} handleEndGame={() => handleEndGame() } />
      <div className={isGameStarted ? 'actions is-hidden' : 'actions'}>
        <h1>{mode}</h1>
        <Button color="primary" onClick={() => handleStartGame(1)}>Level 1</Button>
        <Button color="primary" onClick={() => handleStartGame(2)}>Level 2</Button>
        <Button color="warning" onClick={() => handleStartGame(3)}>Level 3</Button>
        <Button color="danger" onClick={() => handleStartGame(4)}>Level 4</Button>
      </div>
      <Container className={isGameStarted ? '' : 'is-hidden'}>
        <InGameNotification msg={notificationMsg} />
        <Section className="question">
          <h1>{gameQuestions && gameQuestions[currentIndex][0]} {ARITHMETIC_MODES_CONFIG[mode]['sign']} {gameQuestions && gameQuestions[currentIndex][1]} = <span className="question-mark">{answerText}</span></h1>
        </Section>
        <Section className="numpad">
          <Columns multiline="true">
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(1)}}>1</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(2)}}>2</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(3)}}>3</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(4)}}>4</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(5)}}>5</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(6)}}>6</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(7)}}>7</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(8)}}>8</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(9)}}>9</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" color="primary" onClick={() => {onInput(0)}}>0</Button></Columns.Column>
            <Columns.Column><Button rounded={true} size="large" outlined="true" color="primary" onClick={() => {onInput(-1)}}><span className="emoji">⬅️</span></Button></Columns.Column>
          </Columns>
        </Section>
        <Section>
          <h6>{currentIndex+1} of {NUM_QUESTIONS}</h6>
          <Progress color="primary" max={NUM_QUESTIONS} value={currentIndex+1} />
        </Section>
      </Container>
    </div>
  )
}

export default SimpleArithmeticApp