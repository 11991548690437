import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import 'bulma/css/bulma.min.css';
import App from './App'
import SimpleArithmeticApp from './SimpleArithmeticApp'
import BingoMathApp from './BingoMathApp';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "math",
    element: <SimpleArithmeticApp />,
  },
  {
    path: "math/add",
    element: <SimpleArithmeticApp mode="Addition" />,
  },
  {
    path: "math/bingo/add",
    element: <BingoMathApp mode="Addition" size={3} />,
  },
  {
    path: "math/subtract",
    element: <SimpleArithmeticApp mode="Subtraction" />,
  },
  {
    path: "math/bingo/subtract",
    element: <BingoMathApp mode="Subtraction" size={3} />,
  },
  {
    path: "math/multiply",
    element: <SimpleArithmeticApp mode="Multiplication" />,
  },
]);


// ========================================

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
